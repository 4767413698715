<template>
  <div class="applyshop">
    <!-- 主体 -->
    <div class="mainbody">
      <div class="mainbody-title">
        <span>申请开店</span> - <span>{{ reg }}</span> -
        <span>{{ prefixName }}</span>
      </div>
      <Steps :steps="1" />

      <div class="personal-info">
        <div class="info-name">姓名:&nbsp;&nbsp;{{ legalName }}</div>
        <div class="info">
          <div class="info-left">
            <div class="IDnum pd37">
              <p>身份证号:</p>
              <a-input class="w350" v-model="idCard" :maxLength="18" />
            </div>
            <div class="politics pd37">
              <p>政治面貌:</p>
              <a-select :value="politicalStatus" style="width: 220px" @select="getval">
                <a-select-option v-for="item in politics" :key="item">
                  {{ item }}
                </a-select-option>
              </a-select>
            </div>
            <div class="address pd37">
              <p>住所</p>
              <a-textarea class="w350" v-model="stayAddr" :auto-size="{ minRows: 3, maxRows: 3 }" />
            </div>
            <div class="Email pd37">
              <p>电子邮箱:</p>
              <a-input class="w350" v-model="email" />
            </div>
            <div class="postal-code pd37">
              <p>文化程度</p>
              <a-select :value="eduLevel" style="width: 220px" @select="(e) => (eduLevel = e)">
                <a-select-option v-for="item in education" :key="item">
                  {{ item }}
                </a-select-option>
              </a-select>
            </div>

            <div class="small-scale pd37" v-if="idCardHand">
              <p class="small-scal-title"><span class="red">*</span> 上传身份证照片</p>
              <div class="upload-rel">
                <div class="upload">
                  <div class="upload-item">
                    <div class="upload-btn id-front" :class="{'nobackground':idFront}" @click="uploadIdCard('idFront')">
                      <img v-if="idFront" :src="idFront" alt="" class="idPic">
                      <a-spin class="spin" spinning v-if="isLoading&&uploadType ==='idFront'" />
                      <a-icon v-else-if="!idFront" type="plus-circle" class="plus" theme="filled" />
                    </div>
                    <div class="upload-msg">* 请上传手持身份证人像面</div>
                  </div>
                  <div class="upload-item">
                    <div class="upload-btn id-back" :class="{'nobackground':idBack}" @click="uploadIdCard('idBack')">
                      <img v-if="idBack" :src="idBack" alt="" class="idPic">
                      <a-spin class="spin" spinning v-if="isLoading&&uploadType =='idBack'" />
                      <a-icon v-else-if="!idBack" type="plus-circle" class="plus" theme="filled" />
                    </div>
                    <div class="upload-msg">* 请上传手持身份证国徽面</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="Email pd37" v-if="bankImg">
              <p><span class="red">*</span> 银行账号:</p>
              <a-input style="width: 350px" placeholder="请输入电子邮箱" v-model="bankAccount" disabled />
            </div>
            <div class="small-scale pd37" v-if="bankImg">
              <p class="small-scal-title"><span class="red">*</span> 上传银行卡照片</p>
              <div class="upload-rel">
                <div class="upload">
                  <div class="upload-item">
                    <div class="upload-btn bank-front" :class="{'nobackground':bankFront}"
                      @click="uploadIdCard('bankFront')">
                      <img v-if="bankFront" :src="bankFront" alt="" class="idPic">
                      <a-spin class="spin" spinning v-if="isLoading&&uploadType =='bankFront'" />
                      <a-icon v-else-if="!bankFront" type="plus-circle" class="plus" theme="filled" />
                    </div>
                    <div class="upload-msg">* 请上传银行卡正面</div>
                  </div>
                  <div class="upload-item">
                    <div class="upload-btn bank-back" :class="{'nobackground':bankBack}"
                      @click="uploadIdCard('bankBack')">
                      <img v-if="bankBack" :src="bankBack" alt="" class="idPic">
                      <a-spin class="spin" spinning v-if="isLoading&&uploadType =='bankBack'" />
                      <a-icon v-else-if="!bankBack" type="plus-circle" class="plus" theme="filled" />
                    </div>
                    <div class="upload-msg">* 请上传银行卡反面</div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="info-right">
            <div class="Phone pd37">
              <p>移动电话</p>
              <a-input v-model="bankReservedMobile" :maxLength="11"
                @input="bankReservedMobile=$event.target.value.replace(/[^\d]/g,'')" class="w350" />
            </div>
            <div class="nation pd37">
              <p>民族</p>
              <a-input v-model="belongNation" style="width: 130px" />
            </div>
            <div class="postal-code pd37">
              <p>身份证有效期</p>
              <a-space>
                <!-- <a-range-picker format="YYYY-MM-DD" :value="[idCardStartDate, idCardEndDate]" @change="onIndateChange" class="w350" /> -->
                <a-date-picker v-model="idCardStartDate" placeholder="开始日期" :disabled-date="disabledStartDate"
                  style="width: 135px">
                </a-date-picker>
                至
                <a-date-picker v-if="!long" v-model="idCardEndDate" placeholder="结束日期" :disabled-date="disabledEndDate"
                  style="width: 135px">
                </a-date-picker>
                <a-checkbox v-model="long" @change="idCardEndDate = undefined">长期
                </a-checkbox>
              </a-space>
            </div>
            <div class="postal-code pd37 pd40">
              <p>邮政编码</p>
              <a-input v-model="postalCode" class="w350" />
            </div>
            <div class="annotation">
              <p>
                <span class="red" v-if="dy">{{error.firstAltName}}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="info-bottom">
          <a-row type="flex" :gutter="40" class="pd37" v-if="needRealNameRegImg">
            <a-col :span="24">
              <p>上传工商实名注册截图（参考右图）</p>
            </a-col>
            <a-col :span="6">
              <div class="add-gs" @click="uploadIdCard('realNameRegImg')">
                <template v-if="realNameRegImg">
                  <img :src="realNameRegImg">
                  <img class="clear" @click.stop="realNameRegImg = null" src="@/assets/images/u895.svg" alt="">
                  <!-- <div class="mark" @click.stop="showImg(realNameRegImg)">
                    <a-space>
                      <a-icon type="eye"/>
                      <a-icon type="upload" @click.stop="uploadIdCard('realNameRegImg')"/>
                    </a-space>
                  </div> -->
                </template>
                <div v-else>
                  <p>
                    <a-icon type="plus"></a-icon>
                  </p>
                  <div>工商实名注册截图</div>
                </div>
              </div>
            </a-col>
            <a-col :span="18">
              <div class="add-step">
                <a-row class="step" type="flex" style="margin-left: 0;margin-right: 0;" :gutter="60">
                  <a-col :span="8">
                    <p>步骤一：手机扫描二维码下载“登记注册身份验证APP”</p>
                    <img @click="showImg(require('@/assets/images/ICBC-step/u1216.png'))"
                      src="@/assets/images/ICBC-step/u1216.png" alt="">
                  </a-col>
                  <a-col :span="8">
                    <p>步骤二： 点击“新用户注册”，完成注册及身份验证</p>
                    <img @click="showImg(require('@/assets/images/ICBC-step/u1218.png'))"
                      src="@/assets/images/ICBC-step/u1218.png" alt="">
                  </a-col>
                  <a-col :span="8">
                    <p>步骤三：按下图选择选项，设置成功后将该页截图上传</p>
                    <img @click="showImg(require('@/assets/images/ICBC-step/u1217.png'))"
                      src="@/assets/images/ICBC-step/u1217.png" alt="">
                  </a-col>
                </a-row>
              </div>
            </a-col>
          </a-row>

          <div class="small-scale pd37" style="width: 350px">
            <p class="small-scal-title">小规模纳税人登记名称</p>
            <a-row type="flex" align="middle" class="tp10">
              <a-col :span="4">{{prefixName}}</a-col>
              <a-col :span="10" style="padding-left:1px">
                <a-input class="pl" style="width: 100%" v-model="firstAltName" @change="firstAltName?dy=false:dy=true">
                </a-input>
              </a-col>
              <a-col :span="9" align="center">{{ suffixName }}</a-col>
            </a-row>
            <p>
              备选1：&nbsp;&nbsp;
              <a-input class="pl" v-model="secondAltName" />
            </p>

            <p>
              备选2：&nbsp;&nbsp;
              <a-input class="pl" v-model="thirdAltName" />
            </p>
          </div>
        </div>

        <!-- 暂时弃用 -->
        <!-- <div class="signtory">
          <span>授权他人为该交易主体的合同签署人</span>
          &nbsp;&nbsp;
          <a-switch @change="swt">
            <a-icon slot="checkedChildren" type="check" />
            <a-icon slot="unCheckedChildren" type="close" />
          </a-switch>
          <a-row
            type="flex"
            align="middle"
            class="ant-row"
            :class="sw != true ? 'opct' : ''"
          >
            <a-col :span="2">签署人姓名:</a-col>
            <a-col :span="7"
              ><a-input
                v-model="signerName"
                placeholder="姓名"
                class="notact-input"
                :class="signerName != '' ? 'activate-input' : ''"
            /></a-col>
            <a-col :span="2"></a-col>
            <a-col :span="2" align="center">身份证号: </a-col>
            <a-col :span="7"
              ><a-input
                v-model="signerIdCard"
                placeholder="身份证号"
                class="notact-input"
                :maxLength="18"
                :class="signerIdCard != null ? 'activate-input' : ''"
            /></a-col>
            <a-col :span="5"></a-col>
          </a-row>
          <a-row
            type="flex"
            align="middle"
            class="ant-row"
            :class="sw != true ? 'opct' : ''"
          >
            <a-col :span="2">签署手机号：</a-col>
            <a-col :span="7"
              ><a-input
                v-model="signerMobile"
                placeholder="手机号"
                :maxLength="11"
               @input="signerMobile=$event.target.value.replace(/[^\d]/g,'')"
                class="notact-input"
                :class="signerMobile != null ? 'activate-input' : ''"
            /></a-col>
          </a-row>
        </div> -->
      </div>
      <div class="next">
        <a-space :size="46">
          <a-button type="primary" @click="$router.push(`/account-center/apply?id=${$route.query.individualId}`)">
            查看个人信息
          </a-button>
          <a-button style="width: 100px" type="primary" @click="next" :loading="loading"><span v-if="!loading">提交</span>
          </a-button>
        </a-space>

      </div>
    </div>

    <a-modal :width="800" :visible="imgVisible" :footer="null" @cancel="imgVisible = false">
      <img style="width: 100%" :src="previewImage" />
    </a-modal>
    <!-- 上传img -->
    <input v-show="false" id="addImage" ref="addImage" class="upload" type="file" name="addImage" multiple="multiple"
      accept="image/png,image/jpeg,image/gif,image/jpg" @change="getImgFile" />
  </div>
</template>
<script>
import { addapply, selectregion } from '../../api/seller'
import { echo } from '../../api/user'
import Steps from './component/steps.vue'
import { uploadPhoto } from "../../api/utils";
import { politics, education } from '@/utils/constArr'
import moment from 'moment'
var myreg = /^1[3-9][0-9]{9}$/
const regEmail = /.{1,}@.{1,}\..{1,}/
export default {
  components: {
    Steps
  },
  data() {
    return {
      imgVisible: false,
      previewImage: '',
      politics,
      education,
      steps: 2,
      reg: '个体工商户注册申请',

      sw: false, //签署人旋钮
      legalName: '', // 法人姓名
      idCard: '', // 法人身份证号
      sex: null, // 性别
      belongNation: '', // 民族
      stayAddr: '', // 住所地址
      // supplierName: "", // 供应商名称
      bankReservedMobile: '', // 银行预留手机号
      politicalStatus: '群众', // 政治面貌
      eduLevel: '高中', //文化程度
      email: '', // 电子邮件
      postalCode: '', // 邮编
      prefixName: '', // 公司注册名前缀
      suffixName: '', // 公司注册名后缀
      firstAltName: '', // 第一工商名称
      secondAltName: '', // 第二工商名称
      thirdAltName: '', // 第三工商名称
      isAuthSigner: '0', // 是否授权人（0：不授权，1:授权）
      signerName: '', // 签署人姓名
      signerIdCard: null, // 签署人身份证号
      signerMobile: null, // 签署人手机号
      regionId: '', //祱区id
      taxRegionName: '', //祱区名称
      commercialName: '', //关联公司名字
      relatedPurchaserId: '', //关联采购商ID
      idCardEndDate: undefined, // 身份证结束时间
      idCardStartDate: undefined, // 开始时间
      error: {
        email: '请填写正确的电子邮箱',
        firstAltName: '请填写第一工商名称',
        signerName: '请填输入签署人姓名',
        signerIdCard: '请填输入签署人身份证号',
        signerMobile: '请填输入正确的签署人手机号'
      },
      isLoading: false,//上传图片
      uploadType: '',//图片上传类型
      idFront: '', //身份证正面
      idBack: '', //身份证反面
      bankFront: '', //银行卡正面
      bankBack: '', //银行卡反面
      bankAccount: '',//银行卡号
      idCardHand: false, //是否需要手持身份证
      bankImg: false,// 是时候需要银行卡照片
      needRealNameRegImg: false, // 是否需要工商截图
      realNameRegImg: null,
      loading: false,
      dy: false,
      long: false // 身份证长期
    }
  },
  created() {
    this.getID()
    this.getface()
  },
  mounted() { },
  methods: {
    //点击上传按钮
    uploadIdCard(type) {
      this.uploadType = type
      this.$refs.addImage.dispatchEvent(new MouseEvent('click'))
    },
    getImgFile(e) {
      if (this.uploadType !== 'realNameRegImg') {
        if (e.target.files[0].type.indexOf('image') === -1) {
          this.$message.error('请上传正确的格式')
          e.target.value = null
          return
        }
        if ((e.target.files[0].size / 1024 / 1024) > 3) {
          this.$message.error('图片大小超过3MB，请进行图片压缩处理再上传识别')
          e.target.value = null
          return
        }
      }
      this.isLoading = true
      uploadPhoto(Array.from(e.target.files)[0], (url) => {
        this.isLoading = false
        // this.form[this.uploadLabel] = url
        this[this.uploadType] = url
        this.uploadType = ''
      })
      e.target.value = null
    },
    getface() {
      // face({ bizNo: this.$route.query.bizNo }).then((res) => {
      //   if (res.code == 200) {
      //     this.idCard = res.data.id
      //     this.legalName = res.data.name
      //     this.sex = res.data.gender === '男' ? 1 : 0
      //     this.belongNation = res.data.race
      //     this.stayAddr = res.data.address
      //     this.firstAltName=res.data.name
      //   } else {
      //     this.$message.error(res.message)
      //   }
      // })
      echo({ individualId: this.$route.query.individualId }).then(res => {
        if (res.code == 200) {
          this.idCard = res.data.idCard
          this.legalName = res.data.legalName
          // this.sex = res.data.gender === '男' ? 1 : 0
          this.belongNation = res.data.belongNation
          this.stayAddr = res.data.stayAddr
          this.firstAltName = res.data.legalName
          this.idCardStartDate = res.data.idCardStartDate
          this.bankAccount = res.data.bankAccount
          if (res.data.idCardEndDate === '长期') {
            this.long = true
          } else {
            this.idCardEndDate = res.data.idCardEndDate
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getID() {
      this.bankReservedMobile = this.$route.query.bankReservedMobile
      this.regionId = this.$route.query.regionId
      this.taxRegionName = this.$route.query.taxRegionName
      this.commercialName = this.$route.query.commercialName
      this.relatedPurchaserId = this.$route.query.relatedPurchaserId
      selectregion().then(
        (res) => {
          let data = res.data.find(item => {
            return item.id == this.$route.query.regionId
          })
          this.prefixName = JSON.parse(data.config).registrationPrefix
          this.suffixName = JSON.parse(data.config).registrationSuffix
          this.bankImg = data.regionConfig.bankImg
          this.idCardHand = data.regionConfig.idCardHand
          this.needRealNameRegImg = data.regionConfig.needRealNameRegImg
        }
      )
    },
    swt(e) {
      this.sw = e
      // this.isAuthSigner= (e==true?'1':'0')
      if (e == true) {
        this.isAuthSigner = '1'
      } else {
        this.isAuthSigner = '0'
      }
    },
    next() {
      let verify = false
      if (this.idCardStartDate && !this.long && !this.idCardEndDate) {
        this.$message.error('请填写正确的身份证有效期')
      } else if (this.email && !regEmail.test(this.email)) {
        this.$message.error(this.error.email)
      } else if (!this.firstAltName) {
        this.dy = true
        this.$message.error(this.error.firstAltName)
      } else if (this.isAuthSigner == '1' && !this.signerName) {
        this.$message.error(this.error.signerName)
      } else if (this.isAuthSigner == '1' && !this.signerIdCard) {
        this.$message.error(this.error.signerIdCard)
      } else if (this.isAuthSigner == '1' && (this.signerIdCard == this.idCard)) {
        this.$message.error('纳税人身份证与签属人身份证不能相同')
      } else if (this.isAuthSigner == '1' && !myreg.test(this.signerMobile)) {
        this.$message.error(this.error.signerMobile)
      } else if (this.needRealNameRegImg && !this.realNameRegImg) {
        this.$message.error('请上传工商实名注册截图')
      } else if (this.idCardHand || this.bankImg) {
        if (!this.idFront) {
          this.$message.error('请添加手持身份证正面照片');
          return
        } else if (!this.idBack) {
          this.$message.error('请添加手持身份证反面面照片');
          return
        }
        if (!this.bankFront) {
          this.$message.error('请添加银行卡正面照片');
          return
        } else if (!this.bankBack) {
          this.$message.error('请添加银行卡反面面照片');
          return
        }
        verify = true;
      } else {
        verify = true
      }
      if (verify) {
        this.loading = true
        const config = {
          legalName: this.legalName,
          idCard: this.idCard,
          sex: this.sex,
          belongNation: this.belongNation,
          supplierName: this.prefixName + this.firstAltName + this.suffixName,
          bankReservedMobile: this.bankReservedMobile,
          politicalStatus: this.politicalStatus,
          eduLevel: this.eduLevel,
          email: this.email,
          regionId: this.regionId,
          taxRegionName: this.taxRegionName,
          postalCode: this.postalCode,
          prefixName: this.prefixName,
          suffixName: this.suffixName,
          firstAltName: this.firstAltName,
          secondAltName: this.secondAltName,
          thirdAltName: this.thirdAltName,
          isAuthSigner: this.isAuthSigner,
          signerName: this.signerName,
          signerIdCard: this.signerIdCard,
          signerMobile: this.signerMobile,
          stayAddr: this.stayAddr,
          purchaserName: this.commercialName,
          purchaserId: this.relatedPurchaserId,
          idCardEndDate: this.long && this.idCardStartDate ? '长期' : (this.idCardEndDate ? moment(this.idCardEndDate).format('YYYY-MM-DD') : undefined),
          idCardStartDate: this.idCardStartDate ? moment(this.idCardStartDate).format('YYYY-MM-DD') : undefined,
          realNameRegisterImgUrl: this.realNameRegImg || null,
        }
        if (this.idCardHand) {
          config.idCardHandFrontUrl = this.idFront
          config.idCardHandBackUrl = this.idBack
        }
        if (this.bankImg) {
          config.bankCardImgFrontUrl = this.bankFront
          config.bankCardImgBackUrl = this.bankBack
        }
        addapply(config).then((res) => {
          this.loading = false
          if (res.code == 200) {
            this.$store.dispatch("getuserMenu")
            this.$router.replace({
              path: '/accomplish',
              query: {
                id: res.data,
              }
            })
          } else {
            this.$message.error(res.message)
          }
        }).catch(r => {
          this.loading = false
          this.$message.error(r.message)
        })
      }
    },
    getval(e) {
      this.politicalStatus = e
    },
    onIndateChange(m, e) {
      console.log(e);
      this.idCardStartDate = e[0]
      this.idCardEndDate = e[1]
    },
    disabledStartDate(current) {
      return current && this.idCardEndDate && current > moment(this.idCardEndDate)
    },
    disabledEndDate(current) {
      return current && this.idCardStartDate && current < moment(this.idCardStartDate)
    },
    showImg (url) {
      this.previewImage = url
      this.imgVisible = true
    }
  }
}
</script>
<style lang="less" scoped>
.applyshop {
  margin-bottom: 100px;
  max-width: 1440px;
  min-width: 990px;
  @media screen and (max-width: 1440px) {
    padding: 0 15px;
  }
  margin: 20px auto;
  .mainbody {
    min-height: 1064px;
    padding-bottom: 40px;
    background-color: #ffffff;
    position: relative;
    .mainbody-title {
      :nth-child(1) {
        font-size: 20px;
      }
      padding: 30px 0 0 50px;
      color: rgba(0, 0, 0, 0.847058823529412);
      font-size: 16px;
      font-weight: 700;
    }
    .personal-info {
      .info-name {
        padding: 50px 0 0 120px;
        font-size: 20px;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.847058823529412);
      }
      .info {
        display: flex;
        padding: 0 0 0 120px;
        font-size: 14px;
        font-weight: 400;
        .info-left {
          .IDnum {
            padding: 20px 0 35px 0;
          }
        }
        .info-right {
          padding: 0 0 0 85px;
          .Phone {
            padding: 20px 0 35px 0;
          }
          .annotation {
            padding: 0 0 0 0px;
            position: relative;
            p {
              left: -90px;
              position: absolute;
              padding: 46px 0 0 0;
              line-height: 20px;
              width: 375px;
            }
          }
        }
        .pd40 {
          padding-top: 40px;
        }
      }
      .signtory {
        padding: 0 0 0 120px;
        font-size: 15px;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.847058823529412);
        .ant-row {
          font-size: 14px;
          font-weight: 400;
          padding: 15px 0;
          .notact-input {
            background-color: rgba(245, 245, 245, 1);
          }
          .activate-input {
            background-color: #ffffff;
          }
        }
        .opct {
          opacity: 0;
        }
      }
    }
    .tp10 {
      padding: 10px 0 0 0;
    }
    .w54 {
      display: inline-block;
    }
    .next {
      text-align: center;
    }
  }
  .w350 {
    width: 350px;
    @media screen and (max-width: 1440px) {
      width: 270px;
    }
  }
  .w80 {
    width: 112px;
  }
}
.red {
  color: red;
}
.pd37 {
  padding: 0 0 30px 0;
  line-height: 30px;
}
.small-scale {
  .pl {
    width: 145px;
    padding: 0 0 0 10px !important;
  }
  p {
    padding: 10px 0 0 0;
  }
  :nth-child(1) {
    padding: 0;
  }
  .small-scal-title {
    font-size: 14px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.847058823529412);
  }
  .upload-rel {
    position: relative;
    height: 250px;
  }
  .upload {
    display: flex;
    position: absolute;
    .upload-item {
      margin-right: 20px;
      .upload-msg {
        color: red;
      }
      .upload-btn {
        width: 350px;
        height: 214px;
        background: inherit;
        background-color: rgba(255, 255, 255, 1);
        box-sizing: border-box;
        border-width: 1px;
        border-style: solid;
        border-color: #e5e5e5;
        border-radius: 20px;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        text-align: center;
        background-repeat: no-repeat;
        .spin {
          margin-top: 100px;
        }
        &.id-front {
          background-image: url(../../assets/images/front.png);
          background-size: cover;
        }
        &.id-back {
          background-image: url(../../assets/images/back.png);
          background-size: cover;
        }
        &.bank-front {
          background-image: url(../../assets/images/bank-front.png);
          background-size: cover;
        }
        &.bank-back {
          background-image: url(../../assets/images/bank-back.png);
          background-size: cover;
        }
        &.nobackground {
          background-image: none;
        }
        .idPic {
          // width:100% ;
          height: 100%;
          object-fit: cover;
        }
        .plus {
          position: absolute;
          color: rgba(0, 97, 255, 1);
          left: 50%;
          top: 50%;
          z-index: 2;
          font-size: 60px;
          margin-left: -30px;
          margin-top: -30px;
        }
      }
    }
  }
}

.add-gs {
  height: 360px;
  background-color: rgba(242, 242, 242, 1);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(215, 215, 215, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  position: relative;
  img {
    width: 100%;
  }
  i {
    font-size: 27px;
  }
  .clear {
    width: 30px;
    position: absolute;
    right: -15px;
    top: -15px;
  }
  .mark {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    transition: all 0.3s;
    opacity: 0;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    font-size: 18px;
    cursor: pointer;
  }
  &:hover {
    .mark {
      opacity: 1;
    }
  }
}
.add-step {
  height: 360px;
  background-color: rgba(242, 242, 242, 1);
  .step {
    width: 100%;
    padding: 20px 20px 0 20px;
    p {
      font-family: '微软雅黑', sans-serif;
      font-weight: 400;
      color: #555555;
      line-height: 24px;
      margin-bottom: 24px;
    }
    img {
      cursor: pointer;
      width: 191px;
      @media screen and (max-width: 1344px) {
        width: 145px;
      }
    }
  }
}
.info-bottom {
  padding: 0 30px 0 120px;
}
</style>